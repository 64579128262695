import React, { useEffect, useRef } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPhone, faEnvelope, faMapMarker  } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';

library.add(faFacebook, faInstagram);
library.add(faPhone, faEnvelope, faMapMarker);

const HomePage = () => {    

    

    const phoneNumber = '+381601341800';
  	const emailAddress = 'njuskica.groom@gmail.com';
    const address = 'Kraljevića Marka 36, Novi Sad';
    const googleMapAddress = 'https://www.google.com/maps/place/Nju%C5%A1kica+Grooming+-+Salon+za+negu+pasa/@45.2635593,19.8332402,17z/data=!4m6!3m5!1s0x475b11e294828da5:0x11bf7fab540db2c2!8m2!3d45.2636304!4d19.8352659!16s%2Fg%2F11y3jr61r2?entry=ttu';

    const handlePhoneClick = () => {
        window.location.href = `tel:${phoneNumber}`;
      };
    
      const handleEmailClick = () => {
        window.location.href = `mailto:${emailAddress}`;
      };

      const handleAddressClick = () => {
        window.open(googleMapAddress, '_blank');
      };

  return (
    <div>
         <section id="about">
         <h2>O nama</h2>
         <p>
            Dobrodošli u naš Njuškica Grooming salon! <br/>Mi smo posvećeni da pružimo najbolje moguće usluge vašim ljubimcima.
         </p>
       </section>
        <section id="services" style={{ backgroundColor: "#fff" }}>
         <h2>Naše usluge</h2>
         <div
           style={{
             display: "flex",
             justifyContent: "center",
             alignItems: "center",
             flexWrap: "wrap",
           }}
         >
           <div
             className='service'
           >
             <img
               src="slika3.webp"
               alt="Bathing and Shampooing"
             />
             <h3>Kupanje i šamponiranje</h3>
             <p>
               Uključuje relaksirajuće kupanje sa visokokvalitetnim šamponima prilagođenim potrebama vašeg psa.
             </p>
           </div>
           <div
             className='service'
           >
             <img
               src="slika5.jpeg"
               alt=" Brushing and De-shedding"
             />
             <h3>Četkanje i uklanjanje mrtve dlake</h3>
             <p>
              Profesionalno četkanje i uklanjanje mrtve dlake kako bi se održalo zdravo i čisto krzno vašeg psa.
             </p>
           </div>
           <div
             className='service'
           >
             <img
               src="slika1.webp"
               alt="Hair Trimming and Styling"
               
             />
             <h3>Šišanje i stilizovanje</h3>
             <p>
               Kreativno stilizovanje kako bi vaš pas izgledao preslatko i moderno.
             </p>
           </div>
           <div
             className='service'
           >
             <img
               src="slika4.webp"
               alt="Nail Clipping"
               
             />
             <h3>Sečenje noktiju</h3>
             <p>
               Nežno i precizno sečenje noktiju radi obezbeđivanja komfora vašem psu.
             </p>
           </div>
           <div
            className='service'
           >
             <img
               src="dogEarClean.jpg"
               alt="Ear Cleaning"
               
             />
             <h3>Čišćenje ušiju</h3>
             <p>
                Temeljno čišćenje ušiju radi sprečavanja infekcija i radi održavanje zdravlja vašeg psa.
               
             </p>
           </div>
         </div>
       </section>
       <section id="contact">
         


        <div className='contact-wrapper-bottom'>
        <h2>Kontakt</h2>
         <p>
           Ako imate neka pitanja ili biste hteli da zakažete kod nas, slobodno nas kontaktirajte:
         </p>
         <div className='working-hours-and-map'>
            <div className='working-hours'>
                <p>
                    <h3>Radno vreme</h3>
                    <span>Ponedeljak - Petak: <b>11:00 - 19:00</b></span><br/>
                    <span>Subota: <b>09:00 - 16:00</b></span><br/>
                    <span>Nedelja: <b>neradni dan</b></span><br/>
                </p>
                    <div className='contact-contact-phone-footer' onClick={handlePhoneClick}>
                        <FontAwesomeIcon icon={faPhone}/><span>{phoneNumber}</span> 
                    </div>
                    <div className='contact-contact-email-footer' onClick={handleEmailClick}>
                        <FontAwesomeIcon icon={faEnvelope}/>{emailAddress}
                    </div>
                    <div className='contact-contact-address-footer' onClick={handleAddressClick}>
                        <FontAwesomeIcon icon={faMapMarker}/>{address}
                        
                    </div>

            </div>

            <div id="map">
                <img src="mapa1.png" alt='map' id='map-image'/>
            </div>

         </div>
        
			
		</div>
       </section>
    </div>
  )
}

export default HomePage