import React from "react";

const Footer = () => {
  return (

      <footer>
        <p style={{margin: '0'}}>&copy; 2024 Njuškica Grooming Salon. Sva prava zadržana.</p>
      </footer>

  );
};

export default Footer;
