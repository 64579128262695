import React, { useState } from 'react'
import '../styles/AllStyles.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// index.tsx
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';

library.add(faFacebook, faInstagram, faTiktok);
library.add(faPhone, faEnvelope);

const Header = () => {


	
	const [toggle, setToggle] = useState(false);
	
	const phoneNumber = '+381601341800';
  	const emailAddress = 'njuskica.groom@gmail.com';
	const instagramProfileAddress = 'https://www.instagram.com/njuskica_grooming/';
	const tiktokProfileAddress = 'https://www.tiktok.com/@njuskica_grooming/';

  const handlePhoneClick = () => {
    window.location.href = `tel:${phoneNumber}`;
  };

  const handleEmailClick = () => {
    window.location.href = `mailto:${emailAddress}`;
  };

  const handleLogoClick = () => {
	window.scrollTo({
		top: 0,
		behavior: "smooth",
	  });
  }

  return (
	<header className='header-wrapper'>
		<div className='contact-wrapper'>
			<div className='contact-content'>
				<div className='contact-social'>
				<a href={tiktokProfileAddress} target="_blank" rel="noopener noreferrer">
              		<FontAwesomeIcon icon={faTiktok} />
            	</a>
            	<a href={instagramProfileAddress} target="_blank" rel="noopener noreferrer">
              		<FontAwesomeIcon icon={faInstagram} />
            	</a>
				</div>
				<div className='contact-contact'>
					<div className='contact-contact-phone' onClick={handlePhoneClick}>
						<FontAwesomeIcon icon={faPhone}/><span>{phoneNumber}</span> 
					</div>
					<div className='contact-contact-email' onClick={handleEmailClick}>
						<FontAwesomeIcon icon={faEnvelope}/>{emailAddress}
					</div>
				</div>
			</div>
		</div>
		<div className='nav-wrapper'>
			<div className='nav-content'>
				<div className='nav-content-logo-title'>
					<div className='nav-logo'>
						<img className='logo' src="/njuskica_logo_transparent.svg" alt="Logo" onClick={handleLogoClick}/>
					</div>
					<div className='title-header'>
						<h1>Njuškica Grooming</h1>
				</div>	
					<div className={`nav-navbar-hamburger ${toggle ? 'is-active' : ''}`} onClick={() => setToggle(prevToggle => !prevToggle)}>
						<div className='nav-navbar-hamburger-bar'>

						</div>
						{/* <button onClick={() => setToggle(prevToggle => !prevToggle)}>
							@
						</button> */}
					</div>
				</div>
				
				<div className={`nav-navbar${toggle ? '-expand' : ''}`}>
				
						<a className='anchor-element' href="#about" onClick={() => setToggle(prevToggle => !prevToggle)}>O nama</a>
						<a className='anchor-element' href="#services" onClick={() => setToggle(prevToggle => !prevToggle)}>Naše usluge</a>
						<a className='anchor-element' href="#contact" onClick={() => setToggle(prevToggle => !prevToggle)}>Kontakt</a>
				
				</div>
			</div>
		</div>
	</header>
  )
}

export default Header