import "./App.css";
import React, { useEffect } from "react";
import Header from "./components/Header";
import HomePage from "./components/HomePage";
import Footer from "./components/Footer";


function App() {

  useEffect(() => {
   


    document.querySelectorAll(".anchor-element").forEach((anchor) => {
      anchor.addEventListener("click", function (e) {
        e.preventDefault();
        const targetId = this.getAttribute("href").substring(1);
        const targetElement = document.getElementById(targetId);
        window.scrollTo({
          top: targetElement.offsetTop - 305, // Adjust the offset if needed
          behavior: "smooth",
        });
      });
    });
  }, []);

  return (
    <>
      <Header></Header>
      <HomePage></HomePage>
      <Footer></Footer>
    </>
  );
}

export default App;
